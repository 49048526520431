import React, {forwardRef} from "react";
import {css} from "@emotion/react";

import {InputField} from "@pg-design/inputs";

export interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

type RangeLimitValue = string | number;

interface IProps {
    name: string;
    value: IRange<RangeLimitValue>;
    onChange: (name: string, value: IRange<RangeLimitValue>) => void;
}

export const RangeInput = forwardRef<HTMLDivElement, IProps>((props, inputForwardedRef) => {
    const {name, value, onChange} = props;

    const handlers = {
        onChange: (inputName: string, fieldValue: string) => {
            if (inputName === `${name}__lower`) {
                onChange(name, {...value, lower: fieldValue});
            }
            if (inputName === `${name}__upper`) {
                onChange(name, {...value, upper: fieldValue});
            }
        },
        preventNonDigitChars: (event: React.KeyboardEvent) => {
            const notAllowedCharCodes = ["Minus", "Period", "NumpadAdd", "NumpadSubtract", "Comma", "Equal"];

            if (notAllowedCharCodes.includes(event.code)) {
                event.preventDefault();
            }
        }
    };

    return (
        <div css={[rangeHolder]} ref={inputForwardedRef}>
            <div css={inputHolder} className="range-input-lower">
                <InputField
                    name={`${name}__lower`}
                    value={value && value.lower}
                    error={undefined}
                    onChange={(event) => {
                        handlers.onChange(event.currentTarget.name, event.currentTarget.value);
                    }}
                    placeholder="Od"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={handlers.preventNonDigitChars}
                />
            </div>

            <span>-</span>

            <div css={inputHolder} className="range-input-upper">
                <InputField
                    name={`${name}__upper`}
                    value={value && value.upper}
                    error={undefined}
                    onChange={(event) => {
                        handlers.onChange(event.currentTarget.name, event.currentTarget.value);
                    }}
                    placeholder="Do"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={handlers.preventNonDigitChars}
                />
            </div>
        </div>
    );
});

const rangeHolder = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    span {
        margin-bottom: 1rem;
    }
`;

const inputHolder = css`
    display: inline-flex;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.range-input-lower {
        margin-left: 0;
    }

    &.range-input-upper {
        margin-right: 0;
    }
`;
